import React from 'react';
import Slider from 'react-slick';

import { Container, Row, Col, Button } from 'react-bootstrap';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../components/commons/Seo';
import ImageBackground from '../components/commons/ImageBackground';
import Logotypes from '../components/commons/Logotypes';

import ContentItem from '../components/ContentItem';
import Footer from '../components/commons/Footer';
import Section from '../components/commons/Section';
import RequestCatalogModal from '../components/commons/RequestCatalogModal';

import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'react-intl';
import useScrollTo from '../hooks/useScrollTo';

import ContentImg1 from '../images/shows/content4.jpeg';
import ContentImg2 from '../images/shows/content2.png';
import ContentImg3 from '../images/shows/content3.png';

// import CatalogImg from '../images/shows/1.png';

import '../styles/shows.scss';

const PLATFORMS = ['netflix', 'youtube', 'primevideo', 'roku', 'samsungtvplus', 'apple'];

const CatalogItem = ({ img, isCommingSoon = false }) => {
  const { formatMessage } = useIntl();
  return (
    <div className="catalog-item">
      <GatsbyImage image={img.childImageSharp.gatsbyImageData} />
      {isCommingSoon && (
        <div className="catalog-item__soon">
          {formatMessage({ id: 'shows.catalog_section.coming_soon' })}
        </div>
      )}
    </div>
  );
};

const ShowsContent = () => {
  const pageData = useStaticQuery(graphql`
    query {
      allPlatformsJson {
        nodes {
          id
          title
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      allShowsCatalogJson {
        nodes {
          image {
            childImageSharp {
              gatsbyImageData(formats: [JPG], placeholder: BLURRED)
            }
          }
          coming_soon
        }
      }
      # allFile(filter: { name: { eq: "Kedoo_Shows_Web_Catalogue_2020" }, ext: { eq: ".pdf" } }) {
      #   edges {
      #     node {
      #       publicURL
      #     }
      #   }
      # }
    }
  `);

  const { formatMessage } = useIntl();
  const [footerRef, scrollToFooter] = useScrollTo(null);
  const [isShowRequestCatalogModal, setIsShowRequestCatalogModal] = React.useState(false);

  const platformLogotypes = PLATFORMS.map((id) =>
    pageData.allPlatformsJson.nodes.find((p) => p.id === id)
  );
  const catalogItems = pageData.allShowsCatalogJson.nodes;
  // const catalogFileUrl = pageData.allFile.edges[0].node.publicURL;

  const onShowRequestCatagModal = () => {
    setIsShowRequestCatalogModal(true);
  };
  const onHideRequestCatagModal = () => {
    setIsShowRequestCatalogModal(false);
  };

  return (
    <>
      <SEO title={formatMessage({ id: 'shows.title' })} className="kedoo-shows" />
      <ImageBackground>
        <Section className="w-100">
          <Container>
            <Row>
              <Col md={8} xl={6}>
                <div className="fullsize-section__content">
                  <div className="fullsize-section__logo shows-logo mb-2" />
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({ id: 'shows.main_section.title' })
                    }}
                  />
                  <p className="lead">{formatMessage({ id: 'shows.main_section.content' })}</p>
                  <Button
                    variant="outline-dark"
                    size="lg"
                    onClick={scrollToFooter}
                    className="d-flex align-items-center"
                  >
                    <span className="text-nowrap">
                      {formatMessage({ id: 'commons.contact_us' })}
                    </span>
                    <span className="right-arrow" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
      </ImageBackground>
      <main className="pb-3">
        <Section big>
          <Container>
            <ContentItem orderContentFirst image={ContentImg1}>
              <h2
                dangerouslySetInnerHTML={{
                  __html: formatMessage({ id: 'shows.solutions_section.distribution.title' })
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: formatMessage({ id: 'shows.solutions_section.distribution.content' })
                }}
              />
            </ContentItem>
            <ContentItem image={ContentImg2}>
              <h2>{formatMessage({ id: 'shows.solutions_section.management.title' })}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: formatMessage({ id: 'shows.solutions_section.management.content' })
                }}
              />
            </ContentItem>
            <ContentItem orderContentFirst image={ContentImg3} className="mb-n2 h-md-100">
              <div className="d-flex align-items-start flex-column h-md-100">
                <div className="mb-auto">
                  <h2>{formatMessage({ id: 'shows.solutions_section.service.title' })}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({ id: 'shows.solutions_section.service.content' })
                    }}
                  />
                </div>
                <Button
                  variant="outline-shows"
                  size="lg"
                  onClick={scrollToFooter}
                  className="d-flex align-items-center"
                >
                  <span className="text-nowrap">{formatMessage({ id: 'commons.contact_us' })}</span>
                  <span className="right-arrow" />
                </Button>
              </div>
            </ContentItem>
          </Container>
        </Section>

        <Section>
          <Container>
            <div className="py-2">
              <Logotypes items={platformLogotypes} />
            </div>
          </Container>
        </Section>

        <Section>
          <Container>
            <h2>{formatMessage({ id: 'shows.catalog_section.title' })}</h2>
            <p>{formatMessage({ id: 'shows.catalog_section.content' })}</p>
            {/* <Button variant="shows" size="lg" as="a" href={catalogFileUrl} target="_blank">
              {formatMessage({ id: 'shows.catalog_section.see_catalog' })}
            </Button> */}
            <Button variant="shows" size="lg" onClick={onShowRequestCatagModal}>
              {formatMessage({ id: 'shows.catalog_section.see_catalog' })}
            </Button>
          </Container>
        </Section>
      </main>
      <Footer ref={footerRef} />
      <RequestCatalogModal
        show={isShowRequestCatalogModal}
        onHide={onHideRequestCatagModal}
        catalogName="shows"
      />
    </>
  );
};

export default ShowsContent;
