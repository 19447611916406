import React from 'react';
import classNames from 'classnames';

import { Row, Col } from 'react-bootstrap';
import { GatsbyImage } from 'gatsby-plugin-image';

const Logotypes = ({ items, isChecker = false }: any) => {
  console.log(items);
  return (
    <Row className="d-flex align-items-center justify-content-center">
      {items.map((item: any, i: number) => (
        <Col key={i} xs={6} md={4} lg>
          <div className={classNames('p-1 text-center', {})}>
            <GatsbyImage image={item.image.childImageSharp.gatsbyImageData} alt={item.title} />
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default Logotypes;
